<template>
  <input class="switch" :class="{ error: isError}" type="checkbox" role="switch" :id="id" v-model="value">
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SwitchToggle',
  props: {
    id: {
      type: String,
      optional: false
    },
    label: {
      type: String,
      optional: false
    },
    tickedByDefault: {
      type: Boolean,
      optional: true,
      default: false
    }
  },
  data: () => {
    return {
      // @ts-ignore
      value: false,
      isError: false
    }
  },
  mounted: function () {
    this.value = this.tickedByDefault
  },
  methods: {
    getValue: function ():boolean {
      return this.value
    },
    setValue: function (value: boolean) {
      this.value = value
    },
    errorify: function () {
      this.isError = true
      const element = document.getElementById(this.id as string);
      if (element) element.scrollIntoView({ behavior: "smooth", block: "center" })
      setTimeout(() => {
        this.isError = false
      }, 2000)
    }
  }
})
</script>

<style scoped lang="scss">
.switch {
  position: relative;
  height: 1.5rem;
  width: 3rem;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 9999px;
  background-color: rgba(100, 116, 139, 0.377);
  transition: all .3s ease;
  &.error {
    background-color: red !important;
    transition: background-color .5s ease-in-out;
  }
}

.switch:checked {
  background-color: #1a7282;
}

.switch::before {
  position: absolute;
  content: "";
  left: calc(1.5rem - 1.6rem);
  top: calc(1.5rem - 1.6rem);
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
  border: 1px solid rgba(100, 116, 139, 0.527);
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 10px rgba(100, 116, 139, 0.327);
  transition: all .3s ease;
}

.switch:checked:before {
  transform: translateX(100%);
}

</style>
