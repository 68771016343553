<template>
  <div class="form-check form-check-inline pointer">
    <input class="form-check-input pointer" type="checkbox" :id="id" v-model="value">
    <label class="form-check-label pointer" :class="{error: isError }" :for="id">{{ label }}</label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CheckBox',
  props: {
    id: {
      type: String,
      optional: false
    },
    label: {
      type: String,
      optional: false
    },
    tickedByDefault: {
      type: Boolean,
      optional: true,
      default: false
    }
  },
  data: () => {
    return {
      // @ts-ignore
      value: false,
      isError: false
    }
  },
  mounted: function () {
    this.value = this.tickedByDefault
  },
  methods: {
    getValue: function ():boolean {
      return this.value
    },
    setValue: function (value: boolean) {
      this.value = value
    },
    errorify: function () {
      this.isError = true
      const element = document.getElementById(this.id as string);
      if (element) element.scrollIntoView({ behavior: "smooth", block: "center" })
      setTimeout(() => {
        this.isError = false
      }, 2000)
    }
  }
})
</script>

<style scoped lang="scss">
  .pointer {
    cursor: pointer;
  }
  .error {
    color: red;
    transition: color 800ms linear;
  }
</style>
