<template>
  <div class="app-header">
    <div class="app-header--container">
      <div>
        <img class="app-header--image" src="@/assets/SAFEQCloudOrange.png" alt="SAFEQ Cloud logo"/>
      </div>
      <div class="lang-dropdown">
        <span :class="'fi fi-'+ getFlag(selectedLanguage)"></span>
        <select v-model="selectedLanguage" @change="changeLocale" class="lang-dropdown--select">
          <option
            v-for="(val, i) in languageArray"
            :key="`lang${i}`"
            :value="val"
          >
            {{ val }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'AppHeader',
  data: function () {
    return {
      $store: useStore(),
      languageArray: ['en', 'cz', 'de', 'fr'],
      selectedLanguage: 'en'
    }
  },
  mounted: function () {
    this.selectedLanguage = this.$store.getters.locale
    this.$store.getters.i18n.changeLanguage(this.selectedLanguage)
  },
  methods: {
    changeLocale () {
      this.$store.getters.i18n.changeLanguage(this.selectedLanguage)
      this.$store.commit("setLocale", this.selectedLanguage)
    },
    getFlag (lang: string): string {
      if (lang === 'en') return 'us'
      else return lang
    }
  }
})
</script>

<style scoped lang="scss">
@import "flag-icons/css/flag-icons.min.css";
  .app-header {
    background: linear-gradient(170deg, #ffffff 50%, #eaeaea);
    //background: linear-gradient(170deg, #ff6600 50%, #d75100);
    top: 0;
    width: 100%;
    z-index: 101;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 8px 1px;
    &--container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem 2rem;
    }
    &--image {
      height: 50px;
    }
    .lang-dropdown {
      margin: auto 60px auto 0;
      @media (max-width: 650px) {
        margin: auto 0 auto 0;
      }
      &--select {
        background-color: rgba(234, 234, 234, 0);
        border: none;
      }
      &--select:focus-visible {
        outline: none;
      }
    }
  }
</style>
<script setup lang="ts">
</script>
