import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// @ts-ignore
import i18n from './i18n.js'
import axios from './axios'

import TextInput from '@/components/input/TextInput.vue'
import FormContainer from '@/components/container/FormContainer.vue'
import SelectBox from '@/components/input/SelectBox.vue'
import SubmitButton from '@/components/input/SubmitButton.vue'
import RadioButton from '@/components/input/RadioButton.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import FlexContainer from '@/components/container/FlexContainer.vue'
import TextArea from '@/components/input/TextArea.vue'
import CheckBox from '@/components/input/CheckBox.vue'
import Switch from '@/components/input/SwitchToggle.vue'

const app = createApp(App)
app.component('TextInput', TextInput)
  .component('FormContainer', FormContainer)
  .component('SelectBox', SelectBox)
  .component('SubmitButton', SubmitButton)
  .component('RadioButton', RadioButton)
  .component('ProgressBar', ProgressBar)
  .component('FlexContainer', FlexContainer)
  .component('TextArea', TextArea)
  .component('CheckBox', CheckBox)
  .component('Switch', Switch)
const i18nInstance = i18n(app)

store.commit('initializeStore')
store.commit('setI18n', i18nInstance)
app.use(store).use(router).use(axios, []).mount('#app')
