import { createStore } from 'vuex'
import globalAlertTypes from '../constants/GlobalAlertTypes'

export default createStore({
  state: {
    domain: "",
    apiKey: "",
    embeddedKey: "",
    accountId: 0,
    inProgress: false,
    i18n: {},
    globalAlert: {
      text: "",
      type: globalAlertTypes.error
    },
    importedVendors: ['ricoh'],
    locale: "en",
    progress: {
      usersRegistered: false,
      printersImported: false
    },
    defaults: { defaultpapersize: "", locale: "", theme: "" }
  },
  getters: {
    domain: function (state) {
      return state.domain
    },
    apiKey: function (state) {
      return state.apiKey
    },
    embeddedKey: function (state) {
      return state.embeddedKey
    },
    globalAlert: function (state) {
      return state.globalAlert
    },
    inProgress: function (state): boolean {
      return state.inProgress
    },
    accountId: function (state) {
      return state.accountId
    },
    importedVendors: function (state): string[] {
      return state.importedVendors
    },
    i18n: function (state) {
      return state.i18n
    },
    locale: function (state) {
      return state.locale
    },
    progress: function (state) {
      return state.progress
    },
    defaults: function (state) {
      return state.defaults
    }
  },
  mutations: {
    initializeStore (state): void {
      const globalAlertText = (sessionStorage.getItem("globalAlertText") != null) ? sessionStorage.getItem("globalAlertText") : '';
      const globalAlertTypeString = (sessionStorage.getItem("globalAlertType") != null) ? sessionStorage.getItem("globalAlertType") : '0';
      const globalAlertType: number = parseInt((globalAlertTypeString as string))
      state.globalAlert = { text: (globalAlertText as string), type: globalAlertType }

      const domain = (sessionStorage.getItem("domain") != null) ? sessionStorage.getItem("domain") : ''
      state.domain = (domain as string)
      const apiKey = (sessionStorage.getItem("apiKey") != null) ? sessionStorage.getItem("apiKey") : ''
      state.apiKey = (apiKey as string)
      const embeddedKey = (sessionStorage.getItem("embeddedKey") != null) ? sessionStorage.getItem("embeddedKey") : ''
      state.embeddedKey = (embeddedKey as string)
      const accountId = (sessionStorage.getItem("accountId") != null) ? sessionStorage.getItem("accountId") : '0'
      state.accountId = parseInt(accountId as string)
      const locale = (sessionStorage.getItem("locale") != null) ? sessionStorage.getItem("locale") : 'en'
      state.locale = (locale as string)
      const usersRegistered = (sessionStorage.getItem("usersRegistered") != null) ? sessionStorage.getItem("usersRegistered") : '0'
      state.progress.usersRegistered = usersRegistered === "true"
      const printersImported = (sessionStorage.getItem("printersImported") != null) ? sessionStorage.getItem("printersImported") : '0'
      state.progress.printersImported = printersImported === "true"
      const defaults = sessionStorage.getItem("defaults")
      if (defaults != null) {
        state.defaults = JSON.parse(defaults)
      }
    },
    setDomain (state, domain: string): void {
      if (domain.startsWith("http://")) {
        state.domain = domain.replace("http://", "")
      } else if (domain.startsWith("https://")) {
        state.domain = domain.replace("https://", "")
      } else {
        state.domain = domain
      }
      sessionStorage.setItem("domain", state.domain);
    },
    setApiKey (state, apiKey: string): void {
      state.apiKey = apiKey
      sessionStorage.setItem("apiKey", apiKey);
    },
    setEmbeddedKey (state, embeddedKey: string): void {
      state.embeddedKey = embeddedKey
      sessionStorage.setItem("embeddedKey", embeddedKey);
    },
    setGlobalAlert (state, globalAlert): void {
      state.globalAlert = globalAlert
      sessionStorage.setItem("globalAlertText", globalAlert.text);
      sessionStorage.setItem("globalAlertType", globalAlert.type);
    },
    setInProgress (state, inProgress: boolean): void {
      state.inProgress = inProgress
    },
    setAccountId (state, accountId: number): void {
      state.accountId = accountId
      sessionStorage.setItem("accountId", accountId.toString());
    },
    setImportedVendors: function (state, importedVendors: string[]) {
      state.importedVendors = importedVendors
    },
    setI18n (state, i18n: object): void {
      state.i18n = i18n
    },
    setLocale (state, locale: string): void {
      state.locale = locale
      sessionStorage.setItem("locale", locale);
    },
    setUsersRegistered (state): void {
      state.progress.usersRegistered = true;
      sessionStorage.setItem("usersRegistered", "true");
    },
    setPrintersImported (state): void {
      state.progress.printersImported = true;
      sessionStorage.setItem("printersImported", "true");
    },
    setDefaults (state, defaults): void {
      state.defaults = defaults;
      sessionStorage.setItem("defaults", JSON.stringify(defaults));
    }
  },
  actions: {
  },
  modules: {
  }
})
