<template>
  <div class="progress-component">
    <div class="progress progress--margin">
      <div
        class="progress-bar"
        role="progressbar"
        :aria-valuenow="current"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="'width: ' + current + '%;'"
        />
    </div>
    <div class="progress--labels">
      <small>{{ $t('PROGRESS_BAR.START') }}</small>
      <small>{{ $t('PROGRESS_BAR.AUTHORIZATION') }}</small>
      <small>{{ $t('PROGRESS_BAR.DEFAULTS') }}</small>
      <small>{{ $t('PROGRESS_BAR.USERS') }}</small>
      <small>{{ $t('PROGRESS_BAR.DEVICES') }}</small>
      <small>{{ $t('PROGRESS_BAR.TERMINALS') }}</small>
      <small>{{ $t('PROGRESS_BAR.PC_CLIENT') }}</small>
      <small>{{ $t('PROGRESS_BAR.DONE') }}</small>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProgressBar',
  props: {
    current: {
      type: String,
      optional: false
    }
  }
})
</script>

<style scoped lang="scss">
.progress-component {
  margin: 60px auto 48px auto;
  width: 95%;
  max-width: 700px;
  @media(max-width: 1500px) {
    margin: 50px auto 38px auto;
  }
  @media(max-width: 1300px) {
    margin: 30px auto 18px auto;
  }
  .progress--margin {
    background-color: #dddddd;
    box-shadow: inset rgba(0, 0, 0, 0.2) 2px 4px 8px 1px;

    .progress-bar {
      background-color: #ff6600;
    }
  }

  .progress--labels {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.9rem;
    color: #a0a0a0;
  }
}
</style>
