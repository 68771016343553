<template>
  <div class="text-input" :style="'width: ' + width + ';'">
    <label class="text-input--label" :class="{'error-message': !this.displayAsValid}" :for="id">{{ label }}</label>
    <input
      :type="inputType"
      class="form-control"
      :class="{error: !this.displayAsValid, password: type === 'password'}"
      :id="id"
      :placeholder="placeholder"
      v-model="value"
      :maxlength="maxLength"
      :disabled="disabled"
    >
    <i v-show="type === 'password'" class="bi bi-eye" id="togglePassword" @click="togglePassword"></i>
    <span v-show="!this.displayAsValid" class="error-message smaller">{{ this.validationErrorMessage }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TextInput',
  props: {
    id: {
      type: String,
      optional: false
    },
    placeholder: {
      type: String,
      optional: true,
      default: ''
    },
    type: {
      type: String,
      optional: true,
      default: 'text'
    },
    label: {
      type: String,
      optional: false
    },
    width: {
      type: String,
      optional: true,
      default: '100%'
    },
    validationRegex: {
      type: RegExp,
      optional: true,
      default: /.*/
    },
    validationErrorMessage: {
      type: String,
      optional: true,
      default: ""
    },
    maxLength: {
      type: Number,
      optional: true,
      default: 120
    },
    disabled: {
      type: Boolean,
      optional: true,
      default: false
    }
  },
  data: () => {
    return {
      value: '',
      displayAsValid: true,
      displayEmptyAsCorrect: true,
      lastValidationIndex: 0,
      inputType: "text"
    }
  },
  mounted: function () {
    this.inputType = this.type;
  },
  methods: {
    setValue: function (value: string):void {
      this.value = value
    },
    getValue: function ():string {
      return this.value
    },
    validate: function ():boolean {
      const thisValidationIndex = ++this.lastValidationIndex
      let valid: boolean
      if (this.displayEmptyAsCorrect && this.getValue() === '') {
        valid = true
      } else {
        valid = this.validationRegex.test(this.getValue())
      }
      this.displayAsValid = true
      // one second after user stops typing, ui updates
      setTimeout(() => {
        if (thisValidationIndex === this.lastValidationIndex) {
          this.displayAsValid = valid
        }
      }, 1000)
      return valid
    },
    validateBeforeSubmit: function ():boolean {
      this.displayEmptyAsCorrect = false
      const result = this.validate()
      if (!result) this.scrollIntoView()
      return result
    },
    scrollIntoView: function () {
      const element = document.getElementById(this.id as string);
      if (element) element.scrollIntoView({ behavior: "smooth", block: "center" })
    },
    togglePassword: function () {
      this.inputType = "text";
      setTimeout(() => {
        this.inputType = "password";
      }, 2000)
    }
  },
  watch: {
    value () {
      this.validate()
    }
  }
})
</script>

<style scoped lang="scss">
@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css";
.text-input {
  display: inline-block;
  position: relative;
  margin: 10px 0 10px 0;
  &--label {
    text-align: left;
    display: block;
  }
  & .error {
    border: 1px solid red;
  }
  & .error-message {
    color: red;
  }
  & .password {
    padding-right: 30px;
  }
  .smaller {
    font-size: 0.9rem;
  }
  .bi{
    cursor: pointer;
    position: absolute;
    right: 5px;
    bottom: 8px;
  }
}
</style>
