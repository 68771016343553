<template>
  <div v-show="inProgress" class="fullscreen-spinner">
    <img class="fullscreen-spinner--img" src="@/assets/spinner.gif" alt="spinner"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'FullscreenSpinner.vue',
  data: function () {
    return {
      $store: useStore()
    }
  },
  computed: {
    inProgress: function ():boolean {
      return this.$store.getters.inProgress
    }
  }
})
</script>

<style scoped lang="scss">
  .fullscreen-spinner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    transition: all .4s ease-in-out;
    backdrop-filter: blur(5px) brightness(80%);
    &--img {
      width: 100px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
</style>
