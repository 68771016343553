import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f8764778"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "selectbox",
    style: _normalizeStyle('width: ' + _ctx.width + ';')
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(["selectbox--label", {'error-message': !_ctx.displayAsValid, bold: _ctx.strongLabel}]),
      for: _ctx.id
    }, _toDisplayString(_ctx.label), 11, _hoisted_1),
    _withDirectives(_createElementVNode("select", {
      id: _ctx.id,
      class: _normalizeClass(["form-control arrow", {error: !_ctx.displayAsValid}]),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, [
      (_ctx.disabledOption)
        ? (_openBlock(), _createElementBlock("option", {
            key: 0,
            disabled: "",
            value: _ctx.initialValue
          }, _toDisplayString(this.$t('COMMON.DEFAULT_SELECT_PLACEHOLDER')), 9, _hoisted_3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 10, _hoisted_2), [
      [_vModelSelect, _ctx.value]
    ])
  ], 4))
}