<template>
  <label :for="'area-' + id">{{ label }}</label>
  <textarea class="form-control" :id="'area-' + id" rows="3" :placeholder="placeholder" v-model="value"></textarea>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TextArea',
  props: {
    id: {
      type: String,
      optional: false
    },
    placeholder: {
      type: String,
      optional: true,
      default: ''
    },
    label: {
      type: String,
      optional: false
    }
  },
  data: () => {
    return {
      value: ''
    }
  },
  methods: {
    getValue: function ():string {
      return this.value
    }
  }
})
</script>

<style scoped lang="scss">

</style>
