import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-43544b18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check form-check-inline pointer" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "form-check-input pointer",
      type: "checkbox",
      id: _ctx.id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelCheckbox, _ctx.value]
    ]),
    _createElementVNode("label", {
      class: _normalizeClass(["form-check-label pointer", {error: _ctx.isError }]),
      for: _ctx.id
    }, _toDisplayString(_ctx.label), 11, _hoisted_3)
  ]))
}