import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-00b146fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["submit-button", {right: _ctx.rightAligned}])
  }, [
    _createElementVNode("button", {
      id: 'submit_' + _ctx.id,
      type: "button",
      class: _normalizeClass(["btn btn-primary submit-button--button", {grey: _ctx.grey}]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
    }, _toDisplayString(_ctx.label), 11, _hoisted_1)
  ], 2))
}