<template>
  <progress-bar current="0"/>
  <form-container class="home-view--container">
    <img class="home-view--image" src="../assets/Bud-Y.png" alt="Wizard logo"/>
    <h2 v-html="$t('HOME_VIEW.HEADLINE')"></h2>
    <p>
      {{ $t('HOME_VIEW.P1') }}
      <ul>
        <li>{{ $t('HOME_VIEW.LI1_1') }}</li>
        <li>{{ $t('HOME_VIEW.LI1_2') }}</li>
        <li>{{ $t('HOME_VIEW.LI1_3') }}</li>
      </ul>
    </p>
    <p>
      {{ $t('HOME_VIEW.P2') }}
      <ul>
        <li>{{ $t('HOME_VIEW.LI2_1') }}</li>
        <li>{{ $t('HOME_VIEW.LI2_2') }}</li>
        <li>{{ $t('HOME_VIEW.LI2_3') }}</li>
      </ul>
    </p>
    <p>
      {{ $t('HOME_VIEW.P3') }}
      <ul>
        <li>{{ $t('HOME_VIEW.LI3_1') }}</li>
        <li>{{ $t('HOME_VIEW.LI3_2') }}</li>
      </ul>
    </p>
    <p>
      {{ $t('HOME_VIEW.NOTE') }}
    </p>
    <submit-button id="home" :label="$t('COMMON.NEXT')" :rightAligned=true :submit="proceed" />
  </form-container>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ProgressBar from '@/components/ProgressBar.vue'
export default defineComponent({
  name: 'HomeView',
  components: { ProgressBar },
  data () {
    return {
    }
  },
  methods: {
    proceed: function () {
      this.$router.push('authorization')
    }
  }
})
</script>

<style scoped lang="scss">
.home-view{
  &--container{
    position: relative;
  }
  &--image{
    position: absolute;
    right: 7%;
    top: 50%;
    transform: translate(0, -65%);
    width: 240px;
    @media (max-width: 600px) {
      display: none;
    }
  }
}
</style>
