<template>
  <div class="submit-button" :class="{right: rightAligned}" >
    <button :id="'submit_' + id" type="button" class="btn btn-primary submit-button--button"  :class="{grey: grey}" @click="submit">
      {{ label }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SubmitButton',
  props: {
    id: {
      type: String,
      optional: false
    },
    label: {
      type: String,
      optional: false
    },
    rightAligned: {
      type: Boolean,
      optional: true,
      default: true
    },
    grey: {
      type: Boolean,
      optional: true,
      default: false
    },
    submit: {
      type: Function,
      optional: true,
      default: function () { console.log('submitted') }
    }
  }
})
</script>

<style scoped lang="scss">
 .submit-button {
   text-align: center;
   &--button {
     background-color: #1a7282;
     border: none;
     min-width: 10rem;
     margin-top: 10px;
     padding: 8px 20px;
   }
   &--button:hover {
     background-color: rgb(0 93 104)
   }
 }

 .right {
   text-align: right;
 }
 .grey {
   color: black;
   background-color: #e0e0e0;
 }
 .grey:hover {
   background-color: #d0d0d0;
 }
</style>
