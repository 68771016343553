import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'
import globalAlertTypes from '@/constants/GlobalAlertTypes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/authorization',
    name: 'authorization',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "test" */ '../views/AuthorizationView.vue')
  },
  {
    path: '/defaults',
    name: 'defaults',
    component: () => import(/* webpackChunkName: "test" */ '../views/DefaultsView.vue')
  },
  {
    path: '/printers',
    name: 'printers',
    component: () => import(/* webpackChunkName: "test" */ '../views/PrintersView.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "test" */ '../views/UserSelectionView.vue')
  },
  {
    path: '/entra',
    name: 'entra',
    component: () => import(/* webpackChunkName: "test" */ '../views/EntraView.vue')
  },
  {
    path: '/local-users',
    name: 'local-users',
    component: () => import(/* webpackChunkName: "test" */ '../views/LocalUsersView.vue')
  },
  {
    path: '/terminals',
    name: 'terminals',
    component: () => import(/* webpackChunkName: "test" */ '../views/TerminalSelectionView.vue')
  },
  {
    path: '/pc-client',
    name: 'pc-client',
    component: () => import(/* webpackChunkName: "test" */ '../views/PCClientView.vue')
  },
  {
    path: '/final',
    name: 'final',
    component: () => import(/* webpackChunkName: "test" */ '../views/FinalView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/TestView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name === 'entra' && store.getters.progress.usersRegistered) {
    store.commit('setGlobalAlert', { text: store.getters.i18n.t('COMMON.USERS_ALREADY_REGISTERED'), type: globalAlertTypes.success })
    if (from.name === 'printers') {
      next({ name: 'users' })
    } else {
      next({ name: 'printers' })
    }
  } else if (to.name === 'printers' && store.getters.progress.printersImported) {
    if (store.getters.progress.printersImported && store.getters.progress.usersRegistered) {
      store.commit('setGlobalAlert', { text: store.getters.i18n.t('COMMON.BOTH_ALREADY_DONE'), type: globalAlertTypes.success })
    } else {
      store.commit('setGlobalAlert', { text: store.getters.i18n.t('COMMON.PRINTERS_ALREADY_IMPORTED'), type: globalAlertTypes.success })
    }
    if (from.name === 'terminals') {
      next({ name: 'users' })
    } else {
      next({ name: 'pc-client' })
    }
  } else if (to.name !== 'authorization' && to.name !== 'home' && store.getters.domain === '') {
    store.commit('setGlobalAlert', { text: store.getters.i18n.t('COMMON.FILL_OUT_DOMAIN'), type: globalAlertTypes.warning })
    next({ name: 'authorization' })
  } else {
    next()
  }
})

export default router
