import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, vShow as _vShow, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-02854e8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "id", "placeholder", "maxlength", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "text-input",
    style: _normalizeStyle('width: ' + _ctx.width + ';')
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(["text-input--label", {'error-message': !this.displayAsValid}]),
      for: _ctx.id
    }, _toDisplayString(_ctx.label), 11, _hoisted_1),
    _withDirectives(_createElementVNode("input", {
      type: _ctx.inputType,
      class: _normalizeClass(["form-control", {error: !this.displayAsValid, password: _ctx.type === 'password'}]),
      id: _ctx.id,
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      maxlength: _ctx.maxLength,
      disabled: _ctx.disabled
    }, null, 10, _hoisted_2), [
      [_vModelDynamic, _ctx.value]
    ]),
    _withDirectives(_createElementVNode("i", {
      class: "bi bi-eye",
      id: "togglePassword",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.togglePassword && _ctx.togglePassword(...args)))
    }, null, 512), [
      [_vShow, _ctx.type === 'password']
    ]),
    _withDirectives(_createElementVNode("span", { class: "error-message smaller" }, _toDisplayString(this.validationErrorMessage), 513), [
      [_vShow, !this.displayAsValid]
    ])
  ], 4))
}