<template>
  <app-header/>
  <router-view/>
  <fullscreen-spinner/>
  <global-alert/>
</template>

<script lang="ts">
import AppHeader from '@/components/AppHeader.vue'
import GlobalAlert from '@/components/GlobalAlert.vue'
import FullscreenSpinner from '@/components/FullscreenSpinner.vue'
import { useStore } from 'vuex'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    AppHeader, GlobalAlert, FullscreenSpinner
  },
  data: function () {
    return {
      $store: useStore()
    }
  },
  mounted: function () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const domain = urlParams.get('domain');
    const apiKey = urlParams.get('apiKey');
    const embeddedKey = urlParams.get('embeddedKey');
    console.log(embeddedKey)
    if (domain && domain !== '') this.$store.commit('setDomain', domain)
    if (apiKey && apiKey !== '') this.$store.commit('setApiKey', apiKey)
    if (embeddedKey && embeddedKey !== '') this.$store.commit('setEmbeddedKey', embeddedKey)
    window.location.search = ''
  }
})
</script>

<style lang="scss">
body {
  background-color: #eeeeee;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

h3 {
  text-align: left;
}

.button-container {
  position: relative;
  .absolute-button {
    position: absolute;
    right: 0;
    bottom: 8px;
    padding: 0 !important;
    & .left {
      left: 0;
      right: auto;
    }
  }
}

.grey-button {
  color: #a1a1a1 !important;
  text-decoration: underline !important;
  cursor: pointer;
  padding: 10px 10px 10px 0;
}

.form-control:focus {
  color: #212529;
  ///border-color: #000000;
  outline: 0;
  box-shadow: inset 2px 2px 6px 0rem rgb(135 141 150 / 20%);
  border: 1px solid #c4c7d0;
}
.btn:focus {
  box-shadow: 2px 2px 6px 0rem rgb(135 141 150 / 20%) !important;
}
.btn:active {
  box-shadow: 2px 2px 6px 0rem rgb(135 141 150 / 20%) !important;
}
</style>
