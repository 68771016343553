import { vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f5bf738c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    class: _normalizeClass(["switch", { error: _ctx.isError}]),
    type: "checkbox",
    role: "switch",
    id: _ctx.id,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
  }, null, 10, _hoisted_1)), [
    [_vModelCheckbox, _ctx.value]
  ])
}