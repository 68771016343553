<template>
  <form class="form-container" :class="{wider: this.wider}">
    <slot></slot>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormContainer',
  props: {
    wider: {
      type: Boolean,
      optional: true,
      default: false
    }
  }
})
</script>

<style scoped lang="scss">
.form-container {
  margin: 0 auto 90px auto;
  width: 95%;
  max-width: 700px;
  padding: 30px 50px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 3px 3px 6px 1px;
  @media (max-width: 800px) {
    padding: 10px 15px;
  }
  &.wider {
    max-width: 1000px;
  }
}
</style>
