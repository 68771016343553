<template>
  <div class="flex-container" :style="'justify-content: ' + justifyContent + ';'"><slot/></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FlexContainer',
  props: {
    justifyContent: {
      type: String,
      optional: true,
      default: "space-between"
    }
  }
})
</script>

<style scoped lang="scss">

.flex-container{
  display: flex;
  flex-direction: row;
}
</style>
