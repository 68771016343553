<template>
  <div class="form-check radio">
    <input class="form-check-input" type="radio" name="Radios" :id="'radioButtonFirst_' + id" :value=0 v-model="value">
    <label class="form-check-label" :for="'radioButtonFirst_' + id">
      {{ labelFirst }}
    </label>
  </div>
  <div class="form-check radio">
    <input class="form-check-input" type="radio" name="Radios" :id="'radioButtonSecond_' + id" :value=1 v-model="value" :disabled="secondDisabled">
    <label class="form-check-label" :for="'radioButtonSecond_' + id">
      {{ labelSecond }}
    </label>
  </div>
  <br>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'RadioButton',
  props: {
    id: {
      type: String,
      optional: false
    },
    labelFirst: {
      type: String,
      optional: false
    },
    labelSecond: {
      type: String,
      optional: false
    },
    secondDisabled: {
      type: Boolean,
      optional: true,
      default: false
    }
  },
  data: () => {
    return {
      value: 0
    }
  },
  methods: {
    getValue: function ():number {
      return this.value
    }
  }
})
</script>

<style scoped lang="scss">
.radio {
  margin: 15px 0 0 20px;
}
.form-check-input:checked {
  background-color: #1a7282;
  border-color: #1a7282;
}
.form-check-input:focus {
  border-color: #1a7282;
  outline: 0;
  box-shadow: 0 0 3px .25rem rgba(146, 153, 162, 0.25);
}
</style>
