import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      for: 'area-' + _ctx.id
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _withDirectives(_createElementVNode("textarea", {
      class: "form-control",
      id: 'area-' + _ctx.id,
      rows: "3",
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.value]
    ])
  ], 64))
}