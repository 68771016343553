<template>
  <div
    v-show="text && text != ''"
    class="global-alert"
    :class="{
      'error': type == globalAlertTypes.error,
      'warning': type == globalAlertTypes.warning,
      'success': type == globalAlertTypes.success,
      'slideUp': slideUpAnimation,
      'slideDown': !slideUpAnimation
    }"
    @click="clear"
  >
    <span class="global-alert--close">X</span>
    <div class="global-alert--text">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import globalAlertTypes from '@/constants/GlobalAlertTypes'

export default defineComponent({
  name: 'GlobalAlert',
  data: function () {
    return {
      $store: useStore(),
      slideUpAnimation: true,
      lastCallIndex: 0
    }
  },
  computed: {
    globalAlertTypes: function () {
      return globalAlertTypes
    },
    text: function ():string {
      return this.$store.getters.globalAlert.text;
    },
    type: function ():string {
      return this.$store.getters.globalAlert.type;
    }
  },
  methods: {
    clear: function () {
      this.slideUpAnimation = false;
      this.$store.commit('setGlobalAlert', { text: "", type: globalAlertTypes.error });
    }
  },
  watch: {
    text (value) {
      if (value && value !== "") {
        this.slideUpAnimation = true
        const lastCallIndex = ++this.lastCallIndex
        setTimeout(() => {
          if (lastCallIndex === this.lastCallIndex) this.slideUpAnimation = false
        }, 40000)
        setTimeout(() => {
          if (lastCallIndex === this.lastCallIndex) this.clear()
        }, 41000)
      }
    }
  }
})
</script>

<style scoped lang="scss">
  .global-alert {
    position: fixed;
    text-align: center;
    border-radius: 5px;
    width: 90%;
    max-width: 600px;
    min-height: 60px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 30px 25px;
    z-index: 100;
    cursor: pointer;
    &.slideUp {
      animation: 1s slide-up;
      bottom: 0;
    }
    &.slideDown {
      animation: 1s slide-down;
      bottom: -200px;
    }
    &.error {
      background-color: rgba(253, 233, 236, 0.8);
      border: 1px solid #d00d0d;
      color: #d00d0d;
    }
    &.warning {
      background-color: rgba(227, 215, 144, 0.8);
      border: 1px solid #b7af14;
      color: #6b650a;
    }
    &.success {
      background-color: rgba(244, 253, 233, 0.8);
      border: 1px solid #5d9814;
      color: #5d9814;
    }
    &--text {
      vertical-align: middle;
      font-size: 0.9rem;
    }
    &--close {
      position: absolute;
      z-index: 101;
      top: 5px;
      right: 10px;
    }

    @keyframes slide-up {
      from {
        bottom: -200px;
      }
      to {
        bottom: 0;
      }
    }

    @keyframes slide-down {
      from {
        bottom: 0;
      }
      to {
        bottom: -200px;
      }
    }
  }
</style>
