<template>
  <div class="selectbox" :style="'width: ' + width + ';'">
    <label class="selectbox--label" :class="{'error-message': !displayAsValid, bold: strongLabel}" :for="id">{{ label }}</label>
    <select :id="id" class="form-control arrow" :class="{error: !displayAsValid}" v-model="value">
      <option disabled :value='initialValue' v-if="disabledOption">{{ this.$t('COMMON.DEFAULT_SELECT_PLACEHOLDER') }}</option>
      <slot/>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SelectBox',
  props: {
    id: {
      type: String,
      optional: false
    },
    label: {
      type: String,
      optional: false
    },
    width: {
      type: String,
      optional: true,
      default: '100%'
    },
    mandatory: {
      type: Boolean,
      optional: true,
      default: false
    },
    disabledOption: {
      type: Boolean,
      optional: true,
      default: true
    },
    initialValue: {
      type: String,
      optional: true,
      default: ''
    },
    strongLabel: {
      type: Boolean,
      optional: true,
      default: false
    }
  },
  data: () => {
    return {
      value: '',
      displayAsValid: true
    }
  },
  mounted: function () {
    this.value = this.initialValue
  },
  methods: {
    getValue: function ():string {
      return this.value
    },
    setValue: function (value: string) {
      this.value = value
    },
    validateBeforeSubmit: function ():boolean {
      if (!this.mandatory) return true
      else {
        this.displayAsValid = (this.value !== '')
        if (!this.displayAsValid) this.scrollIntoView()
        return this.displayAsValid
      }
    },
    scrollIntoView: function () {
      const element = document.getElementById(this.id as string);
      if (element) element.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  },
  watch: {
    value () {
      if (!this.displayAsValid && this.value !== '') this.displayAsValid = true
    }
  }
})
</script>

<style scoped lang="scss">
.selectbox {
  display: inline-block;
  margin: 10px 0 10px 0;
  &--label {
    text-align: left;
    display: block;
  }
  .bold {
    font-weight: 600;
  }
  .error {
    border: 1px solid red;
  }
  & .error-message {
    color: red;
  }
}
.arrow {
  background-image: url("@/assets/arrow.svg");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
}
</style>
