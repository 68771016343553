import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-08f583f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-component" }
const _hoisted_2 = { class: "progress progress--margin" }
const _hoisted_3 = ["aria-valuenow"]
const _hoisted_4 = { class: "progress--labels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "progress-bar",
        role: "progressbar",
        "aria-valuenow": _ctx.current,
        "aria-valuemin": "0",
        "aria-valuemax": "100",
        style: _normalizeStyle('width: ' + _ctx.current + '%;')
      }, null, 12, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("small", null, _toDisplayString(_ctx.$t('PROGRESS_BAR.START')), 1),
      _createElementVNode("small", null, _toDisplayString(_ctx.$t('PROGRESS_BAR.AUTHORIZATION')), 1),
      _createElementVNode("small", null, _toDisplayString(_ctx.$t('PROGRESS_BAR.DEFAULTS')), 1),
      _createElementVNode("small", null, _toDisplayString(_ctx.$t('PROGRESS_BAR.USERS')), 1),
      _createElementVNode("small", null, _toDisplayString(_ctx.$t('PROGRESS_BAR.DEVICES')), 1),
      _createElementVNode("small", null, _toDisplayString(_ctx.$t('PROGRESS_BAR.TERMINALS')), 1),
      _createElementVNode("small", null, _toDisplayString(_ctx.$t('PROGRESS_BAR.PC_CLIENT')), 1),
      _createElementVNode("small", null, _toDisplayString(_ctx.$t('PROGRESS_BAR.DONE')), 1)
    ])
  ]))
}